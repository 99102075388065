import { Box, useColorModeValue } from "@chakra-ui/react";
import Link from "next/link";
interface NavLinkProps {
  name: string;
  path: string;
  current_path: string;
  onClose: () => void;
}

const NavLink = ({ name, path, onClose, current_path }: NavLinkProps) => {

  return (
    <Box
      _hover={{
        textDecoration: "underline",
      }}
      px={3}
      py={1}
      lineHeight="inherit"
      rounded="md"
    >
      <Link
        href={path}
        onClick={() => onClose()}
        prefetch={true}
        aria-disabled={path === current_path}
      >
        {name}
      </Link>
    </Box>
  );
};

export default NavLink;
